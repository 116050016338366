@use '../../styles/mixins' as mixins;

.features-block {

	@include mixins.sectionStyles;

	.content-col {
		max-width: 600px;

		p {
			text-wrap: balance;
		}
	}
	
	.features {
		
		@include mixins.grid;;
		gap: var(--wp--preset--spacing--50);
		margin: var(--wp--preset--spacing--50) auto 0;

	}

	svg {
		width: 80px;
	}

	&.dark {

		@include mixins.SVGDarkBg;

		.features-module:not(.animated){

			h3 {
				color: #7FD4B5!important;
			}
	
			.learn-more {
				color: #7FD4B5;
	
				&::before {
					background-color: #7FD4B5;
				}
			}

		}

		p:not(.learn-more) {
			color: var(--wp--preset--color--white);
		}

	}


}